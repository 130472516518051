import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import { baseStyles } from 'suspended-page/styles';

export default makeStyles({
  ...baseStyles,
  landingSkeletonBanner: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(-1.5),
  },
  landingSkeletonText: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  landingSkeletonHeadPaper: {
    display: 'table',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
  },
  landingSkeletonPrices: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(4),
  },
  landingSkeletonYouTube: {
    display: 'table',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '72.5%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});
