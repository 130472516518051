import React from 'react';
import { array, string } from 'prop-types';
import loadable from '@loadable/component';

import { Skeleton } from '@material-ui/lab';

import useStyles from './styles';

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));

const LazyLandingBanner = loadable(() => import('components/banners/landing'));
const LazyTopSignUpSection = loadable(() => import('views/landing/try-for-free-section'));
const LazyHeadlineSection = loadable(() => import('views/landing/headline-section'));
const LazySubSection = loadable(() => import('views/landing/subscription-section'));
const LazyPricesSection = loadable(() => import('views/landing/prices-section'));
const LazySignUpSection = loadable(() => import('views/landing/signup-section'));
// const LazySocialSection = loadable(() => import('views/landing/social-section'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));
const LazyVideoCard = loadable(() => import('components/video'));

const SupendedLandingPage = ({
  navItems = [],
  hash = '',
}) => {
  const styles = useStyles();
  const title = 'Welcome to Total Loyalty';
  const message = 'Digital loyalty made easy for everyone';

  return (
        <>
          <LazyLandingBanner falback={<Skeleton
            animation="wave"
            component="div"
            height={300}
            className={styles.landingSkeletonBanner}
            variant="rect"
            />}/>
            <Hidden xsDown>
              <Typography variant="h2" component="h1" className={styles.heading} align="center">
                {title}
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Typography variant="h2" component="h1" className={styles.headingXs} align="center">
                Welcome to <br/> Total Loyalty
              </Typography>
            </Hidden>
            <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
             {message}
            </Typography>

            <LazyVideoCard
              fallback={
                <Skeleton
                animation="wave"
                component="div"
                height={375}
                variant="rect"
                className={styles.landingSkeletonYouTube}/>
            }/>

            <LazyTopSignUpSection
              fallback={
                <Skeleton
                  animation="wave"
                  component="div"
                  height={200}
                  className={styles.landingSkeletonPrices}
                  variant="rect"
                  />
                }/>

          <section>
            <Typography variant="h3" component="h2" className={styles.fourTopBotMargin} align="center">
             Digital Loyalty simple and affordable
            </Typography>

            <LazyHeadlineSection hash={hash}
              fallback={
                <>
                <Skeleton
                  animation="wave"
                  component="div"
                  height={70}
                  width={500}
                  className={styles.landingSkeletonText}
                  variant="text"
                  />
                  <Skeleton
                    animation="wave"
                    component="div"
                    height={500}
                    width={800}
                    className={styles.landingSkeletonHeadPaper}
                    variant="rect"
                    />
                </>
            }/>
          </section>

          <section>
            <Typography variant="h3" component="h2" className={styles.fourTopBotMargin} align="center">
              Fair Inclusive Subscriptions
            </Typography>

            <LazySubSection
              fallback={
                <Skeleton
                  animation="wave"
                  component="div"
                  height={100}
                  width={500}
                  className={styles.landingSkeletonText}
                  variant="text"
                  />
            }/>
            </section>

            <section>
              <Typography variant="h3" component="h2" className={styles.fourTopBotMargin} align="center">
                Find your subscription
              </Typography>

              <LazyPricesSection
                fallback={
                    <Skeleton
                      animation="wave"
                      component="div"
                      height={200}
                      className={styles.landingSkeletonPrices}
                      variant="rect"
                      />
              }/>
          </section>

          <section>
            <Typography
              variant="h3"
              component="h2"
              className={styles.fourTopBotMargin} align="center">
              Sign up for Total Loyalty
            </Typography>

            <LazySignUpSection />
          </section>

          {/*
            <section >
               <LazySocialSection />
           </section>
            */}

          <LazyOverrideFooter
              navItems={navItems}
              currentItem="Home"
              fallback={
                <Skeleton
                animation="wave"
                component="div"
                height={100}
                style={{
                  marginTop: -5,
                  marginRight: 10,
                  marginLeft: 10,
                }} />
              }
              />
        </>
  );
};

SupendedLandingPage.propTypes = { hash: string, navItems: array };

export default SupendedLandingPage;
